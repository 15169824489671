<template>
  <section id="all-stories" class="section stories">
    <div class="container">
      <div class="stories__block">
        <div class="stories__title">Сборник лесных интерактивных историй</div>
        <div class="stories__text">
          Изучайте добрые эмоции <br />и&nbsp;получайте подарки
          с&nbsp;Kinder<sup class="reg">&reg;</sup>
        </div>
        <a
          @click="gtmEvent('', 'main', 'stories_click')"
          :href="
            hostname.includes('dev')
              ? 'https://kinder-emotions.dev.familyagency.ru/#all-stories'
              : 'https://kinder-emotions.ru/#all-stories'
          "
          class="stories__btn btn btn--white btn--lg _orange"
        >
          Читать истории
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    hostname: location.hostname,
  }),
};
</script>

<style lang="scss" scoped>
.stories {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: rem(770px);
  background: url("../assets/images/animals-sm.png") no-repeat right bottom;
  background-size: 100%;
  @media (min-width: $md) {
    height: rem(730px);
    align-items: center;
    background: url("../assets/images/animals.png") no-repeat center bottom;
    background-size: auto 100%;
  }
  &__block {
    position: relative;
    width: 100%;
    padding: rem(30px) rem(30px) rem(52px);
    margin: rem(73px) 0 0;
    background: #fdde77;
    border-radius: rem(35px);
    letter-spacing: -0.02em;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: rem(130px);
      display: block;
      width: rem(223px);
      height: rem(148px);
      background: url("../assets/images/rainbow-2.png") no-repeat center bottom;
      background-size: contain;
    }
    @media (min-width: $md) {
      width: rem(465px);
      padding: rem(40px) rem(40px) rem(83px);
      margin: 0 0 rem(40px) rem(63px);
      &::before {
        left: rem(166px);
        width: rem(395px);
        height: rem(287px);
      }
    }
  }
  &__title {
    font-family: $font-bold;
    margin-bottom: rem(17px);
    font-size: rem(24px);
    line-height: rem(26px);
    @media (min-width: $md) {
      margin-bottom: rem(20px);
      font-size: rem(40px);
      line-height: rem(42px);
    }
  }
  &__text {
    margin-bottom: rem(20px);
    font-family: $font-reg;
    font-size: rem(16px);
    line-height: rem(20px);
    @media (min-width: $md) {
      margin-bottom: rem(30px);
      font-size: rem(24px);
      line-height: rem(29.5px);
    }
  }
  &__btn {
    font-family: $font-bold;
    position: relative;
    z-index: 1;
    @media (max-width: $md) {
      height: rem(52px);
      font-size: rem(16px);
      padding: 0 rem(40px);
    }
  }
}
</style>
