<template>
  <div class="feedback">
    <form class="feedback-form" :key="indexForm">
      <inputText
        label="Имя"
        :field="name"
        @input="name = $event"
        :class="{
          error: validationStatus($v.name) || this.errorMessage.name,
        }"
        :error="
          (!$v.name.required && $v.name.$error
            ? 'Обязательное поле'
            : !$v.name.rus && $v.name.$error
            ? 'Введите текст на русском языке'
            : '') || this.errorMessage.name
        "
      />
      <inputText
        label="E-mail"
        :field="email"
        @input="email = $event"
        :class="{
          error: validationStatus($v.email) || this.errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email
        "
      />
      <textArea
        label="Сообщение"
        :field="message"
        @input="message = $event"
        :class="{
          error: validationStatus($v.message) || this.errorMessage.message,
        }"
        :error="
          (!$v.message.required && $v.message.$error
            ? 'Обязательное поле'
            : '') || this.errorMessage.message
        "
      />
      <File
        :chosenFileName="chosenFileName"
        @submitMedia="submitMedia"
        @deleteFile="deleteFile"
      />
      <div class="feedback-col feedback__checkbox_list">
        <checkBox
          label="Подтверждаю, что мне больше 18 лет"
          :field="checkbox"
          :class="{
            error: validationStatus($v.checkbox) || errorMessage.checkbox,
          }"
          :error="errorMessage.checkbox"
          @input="checkbox = $event"
        />
        <checkBox
          :label="`Соглашаюсь с <a href='${publicPath}docs/rules.pdf' class='_undeline-link _bold' target='_blank'>Правилами участия</a> и <a href='${publicPath}docs/agreement.docx' class='_undeline-link _bold' target='_blank'> обработкой персональных данных</a>`"
          :field="checkbox2"
          :class="{
            error: validationStatus($v.checkbox2) || errorMessage.checkbox2,
          }"
          :error="errorMessage.checkbox2"
          @input="checkbox2 = $event"
        />
      </div>

      <div class="feedback__footer">
        <button
          type="button"
          class="btn btn--primary btn--in-popup"
          @click="submit()"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          Отправить<img src="../../assets/img/spinner.svg" alt="" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import textArea from "@/components/form/textArea.vue";
import File from "@/components/form/inputFile.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "FeedbackForm",
  data() {
    return {
      ready: false,
      publicPath: "/",
      indexForm: 1,
      name: null,
      email: null,
      message: null,
      subject: 1,
      checkbox: false,
      checkbox2: false,
      attach: "",
      errorMessage: {
        email: null,
        name: null,
        message: null,
        checkbox: null,
        checkbox2: null,
      },

      submitStatus: null,

      chosenFileName: {},
    };
  },

  validations: {
    message: { required },
    email: { required, email },
    name: { required, rus },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
    checkbox2: {
      required,
      sameAs: sameAs(() => true),
    },
  },

  methods: {
    submitMedia(e) {
      let t = this;

      this.chosenFileName = e;

      if (this.chosenFileName.length) {
        getBase64(this.chosenFileName[0]).then(function (data) {
          t.attach = data;
        });
      }
    },
    deleteFile() {
      this.chosenFileName = {};
      this.attach = "";
    },

    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        name: null,
        message: null,
        checkbox: null,
        checkbox2: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      console.log("submit");
      this.$v.$touch();
      if (this.$v.$anyError) {
        const errors = Object.keys(this.errorMessage);
        for (let index = 0; index < errors.length; index++) {
          const element = errors[index];
          if (this.$v[element].$error) {
            this.gtmEvent(element, 'help', 'question_error')
          }
        }
      }
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("SendFeedback", {
            name: this.name,
            email: this.email,
            text: this.message,
            file: this.attach,
            accept: 1,
          })
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              this.captcha = null;

              let fieldsError = null;

              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email;
                fieldsError = true;
                this.gtmEvent('email', "help", "question_error");
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
                this.gtmEvent('login', "help", "question_error");
              }

              if (r.error && r.error.name) {
                [this.errorMessage.name] = r.error.name;
                fieldsError = true;
                this.gtmEvent('name', "help", "question_error");
              }
              if (r.error && r.error.file) {
                [this.errorMessage.attach] = r.error.file;
                fieldsError = true;
                this.gtmEvent('file', "help", "question_error");
              }

              if (r.error && r.error.text) {
                [this.errorMessage.message] = r.error.text;
                fieldsError = r.message;
                this.gtmEvent('text', "help", "question_error");
              }
              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  title: r.error,
                });
                this.gtmEvent(r.error, "help", "question_error");
              }
            } else {
              this.submitStatus = null;
              this.$modal.hide("feedback");
              this.$modal.show("success_feedback", { mail: this.email });
              this.gtmEvent('', "help", "question_send");
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.user) {
        this.indexForm++;
        this.name = this.user.name;
        this.email = this.user.login;
        // this.checkbox = true;
        // this.checkbox2 = true;
      }
    }, 1000);
  },
  components: {
    inputText,
    textArea,
    checkBox,
    File,
  },
};
</script>

<style lang="scss">
.feedback {
  .form-checkbox__caption {
    margin-left: rem(18px);
  }
  &-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__footer {
    width: 100%;
  }
  &__text {
    margin-top: auto;
    margin-bottom: rem(20px);
    padding-right: rem(10px);
    font-size: rem(12px);
  }
  &__checkbox_list {
    margin-bottom: rem(30px);
    .form-checkbox {
      margin-bottom: rem(10px);
    }
  }
  @media (min-width: $md) {
    &-form {
      width: rem(334px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .form-file {
        margin-left: auto;
        margin-top: rem(20px);
        margin-bottom: 0;
      }
    }
    &-col {
      flex: 0 0 47%;
    }
    &__text {
      font-size: rem(16px);
      line-height: rem(19px);
    }
    &__checkbox_list {
      margin-top: rem(20px);
      margin-bottom: rem(28px);
    }
  }
}
</style>
