<template>
  <section class="faq section">
    <div class="container">
      <div class="faq__content">
        <h2 class="section-title">Часто задаваемые вопросы</h2>

        <div class="faq__list">
          <div
            class="faq__item"
            v-for="(item, index) in items"
            :key="index"
            :class="item.isActive ? 'faq__item--active' : ''"
          >
            <div
              class="faq__item-header"
              @click="item.isActive = !item.isActive"
            >
              <h2 class="faq__item-title">
                <span v-html="item.title"></span>
              </h2>
              <div class="faq__item-trigger">
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.36001 2.69776L9.19075 10L16.0215 2.69776"
                    stroke="#3F3F3F"
                    stroke-width="3.65314"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div
              class="faq__item-content"
              v-if="item.isActive"
              v-html="item.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Какие сроки Акции?",
        text: "Чеки можно регистрировать с&nbsp;1&nbsp;мая по&nbsp;30&nbsp;июня 2024&nbsp;года.",
        isActive: false,
      },
      {
        title: "Какие даты выдачи призов?",
        text: "Призы выдаются с&nbsp;8&nbsp;мая 2024&nbsp;г.&nbsp;по&nbsp;30&nbsp;сентября 2024&nbsp;года.",
        isActive: false,
      },
      {
        title: "Как получить гарантированный приз?",
        text: "Гарантированный приз начисляется за&nbsp;первый валидный чек, один раз для каждого участника. Начисления проводятся 2&nbsp;раза за&nbsp;время проведения Акции: с&nbsp;1&nbsp;по&nbsp;10&nbsp;июня 2024&nbsp;г.&nbsp;и&nbsp;с&nbsp;1&nbsp;по&nbsp;10&nbsp;июля 2024&nbsp;г.",
        isActive: false,
      },
      {
        title: "Как быстро проверяются чеки?",
        text: "Обычно на&nbsp;проверку чека уходит до&nbsp;4-х рабочих дней. Нам необходимо проверить, что чек полностью сфотографирован, фотография не&nbsp;смазана, на&nbsp;фото чека нет сгибов и&nbsp;поврежденных частей, вся информация на&nbsp;чеке хорошо видна. Далее чек проверяется в&nbsp;Федеральной Налоговой Службе, и&nbsp;как только от&nbsp;ФНС приходит ответ, что чек валидный&nbsp;&mdash; мы&nbsp;его принимаем. Статус по&nbsp;каждому чеку отображается у&nbsp;Вас в&nbsp;Личном кабинете на&nbsp;сайте.",
        isActive: false,
      },
      {
        title: "Сколько всего призов можно получить?",
        text: "За&nbsp;все время проведения Акции каждый участник может получить не&nbsp;более&nbsp;1 (одного) Еженедельного приза, и&nbsp;не&nbsp;более 1(одного) Главного приза.",
        isActive: false,
      },
      {
        title: "Какие продукты участвуют в&nbsp;Акции?",
        text: 'В&nbsp;Акции принимает участие вся продукция Kinder<sup class="reg">&reg;</sup>',
        isActive: false,
      },
      {
        title: "Как мне получить свой приз?",
        text: "После объвления результатов розыгрыша мы&nbsp;свяжемся с&nbsp;Вами в&nbsp;течение 5&nbsp;рабочих дней для уточнения необходимых данных для получения приза. После мы&nbsp;сообщим вам сроки доставки.",
        isActive: false,
      },
      {
        title: "Мой чек принят, но&nbsp;баллы на&nbsp;карту не&nbsp;пришли",
        text: "Мы&nbsp;начисляем баллы 2&nbsp;раза за&nbsp;акцию&nbsp;&mdash; в&nbsp;начале июня и&nbsp;в&nbsp;начале июля, уведомление о&nbsp;начисленных баллах приложение Пятерочки не&nbsp;отправляет, поэтому вам необходимо самостоятельно проверять баланс баллов.",
        isActive: false,
      },
      {
        title: "Что делать, если мой чек не&nbsp;принят?",
        text: "Информация по&nbsp;непринятым чекам содержится в&nbsp;Личном кабинете. Также Вы&nbsp;можете задать свой вопрос через форму обратной связи, наш модератор проверит Ваши чеки и&nbsp;даст подробный ответ, почему чек не&nbsp;был принят.",
        isActive: false,
      },
      {
        title:
          "Что входит в главный приз?",
        text: "Электронный подарочный сертификат номиналом 200&nbsp;000 рублей на&nbsp;семейное путешествие в&nbsp;парк развлечений &laquo;Сказка&raquo;. Включает в&nbsp;себя дорогу до&nbsp;г. Москвы, проживание, а&nbsp;также билеты в&nbsp;парк развлечений &laquo;Сказка&raquo;.",
        isActive: false,
      },
    ],
  }),
  methods: {},
  mounted() {
    this.$parent.showFooter = true;
  },
};
</script>

<style lang="scss" scoped>
.faq {
  position: relative;
  padding-top: rem(50px);
  padding-bottom: rem(110px);
  z-index: 1;
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  &__content {
    width: 100%;
    padding: rem(60px) rem(25px);
    background: #FFF;
    border-radius: rem(35px);
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__item {
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid rgba($grey, 0.15);
    }
  }

  &__item--active {
    .faq__item-trigger {
      .horizontal {
        opacity: 0;
      }
    }
  }

  &__item-header {
    cursor: pointer;
    position: relative;
    padding: rem(20px) rem(0px) rem(20px) rem(0px);
  }
  &__item-trigger {
    position: absolute;
    top: 50%;
    right: rem(0px);
    transform: translateY(-50%);
    width: rem(29px);
    height: rem(29px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      width: rem(13px);
      height: rem(8px);
    }
    path {
      transition: 0.5s;
      ._pink & {
        fill: #fff;
      }
    }
    ._pink & {
      border-color: #fff;
    }
  }
  &__item-title {
    display: flex;
    font-size: rem(16px);
    padding-right: rem(30px);
    line-height: 1.1;
    font-family: $font-reg;
    transition: color 0.3s ease-in-out;
    span {
      display: inline-block;
      vertical-align: top;
    }
  }

  &__item-content {
    width: 90%;
    padding: rem(0px) rem(0px) rem(15px) 0;
    font-size: rem(16px);
    font-family: $font-reg;
    color: rgba(63, 63, 63, 0.7);
  }
  &-feedback {
    order: 1;
    padding-top: rem(37px);
    font-family: "NuberNext", sans-serif;
    &__title {
      font-size: rem(18px);
      text-transform: uppercase;
    }
    &__text {
      margin-bottom: rem(16px);
      font-size: rem(14px);
    }
    &__btn {
      margin: auto;
    }
  }
  @media (min-width: $sm) {
    padding-top: rem(80px);
    padding-bottom: rem(110px);
    &__content {
      padding: rem(60px) rem(103px);
      border-radius: rem(50px);
    }
    &__item-header {
      padding: rem(30px) rem(70px) rem(30px) rem(0px);
      &:before {
        width: 100%;
        left: 0;
        border-radius: rem(30px);
      }
    }
    &__item-content {
      padding: rem(15px) rem(70px) rem(15px) rem(0px);
      font-size: rem(24px);
    }
    &__item-title {
      padding-right: rem(70px);
      font-size: rem(24px);
      line-height: 1;
    }
    &__item-trigger {
      width: rem(48px);
      height: rem(38px);
      right: rem(15px);
    }
    &-feedback {
      margin-bottom: rem(58px);
      order: 0;
      &__title {
        font-size: rem(24px);
        text-transform: uppercase;
      }
      &__text {
        margin-bottom: rem(23px);
      }
      &__btn {
        width: rem(298px);
        margin: 0;
      }
    }
  }
  @media (min-width: $md) {

  }
}
</style>
<style lang="scss">
.link {
  color: $pink;
  text-decoration: underline;
  ._pink & {
    color: #fff;
  }
}
</style>
