<template>
  <div id=""></div>
</template>

<script>
export default {
  name: "Apmcheck",

  data() {
    return {
      ready: false,
    };
  },

  methods: {},
  // created() {},
  mounted() {
    this.ready = true;
  },

  components: {},
};
</script>

<style lang="scss">
#promo-tools {
  .popup {
    display: flex;
    align-items: flex-start;
    padding-top: rem(120px);
    padding-bottom: rem(50px);
    background: #edfbff;
    backdrop-filter: blur(6px);
    &::-webkit-scrollbar {
      width: rem(5px);
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #ececec;
      border-radius: 13px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $grey;
      transition: all 0.3s ease-in-out;
    }
    &__container {
      width: rem(520px);
      height: auto;
      border: none;
    }
    .check-grabber {
      color: $grey;
      &__fail-icon {
        display: none;
        @include size(#{rem(75px)});
        font-size: 0;
        background: url("../assets/images/icons/error.svg") no-repeat center;
        background-size: contain;
      }
      &__title {
        display: block;
        margin-bottom: rem(20px);
        font-family: $font-bold;
        color: $grey;
        font-size: rem(24px);
        line-height: 1;
        opacity: 1;
      }
      &__sub-title {
        font-size: rem(12px);
        line-height: rem(15px);
        font-family: $font-light;
        color: #797979;
        text-transform: none;
        strong {
          display: block;
          margin-bottom: rem(20px);
          font-family: $font-bold;
          color: $grey;
          font-size: rem(24px);
          line-height: 1;
          opacity: 1;
        }
      }
      &__photos {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0 rem(12px);
      }
      &__photo {
        height: 100%;
        border: none;
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          padding-top: 100%;
        }
        &-item {
          flex: 0 1 18%;
        }
        img {
          object-fit: cover;
          border-radius: rem(20px);
          border: 1px solid rgba($grey, 0.4);
        }
        &-x {
          top: 0;
          right: 0;
          border-radius: 50%;
          transform: none !important;
        }
      }
      &__btn-set {
        grid-row-gap: rem(20px);
        margin-top: rem(16px);
      }
      .btn {
        font-size: rem(18px);
        margin-left: 0;
      }
    }
    &__close {
      top: rem(20px);
      right: rem(20px);
      @include size(#{rem(26px)});
      background: url("../assets/images/icons/close.svg") no-repeat center;
      background-size: contain;
      font-size: 0;
    }
    .btn {
      font-family: $font-bold;
      position: relative;
      border: rem(2px) solid currentColor;
      border-radius: rem(50px);
      box-shadow: none;
      text-transform: none;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: rem(3px);
        left: rem(3px);
        display: block;
        @include size(calc(100% - rem(6px)));
        border-radius: rem(50px);
        background: $orange;
        transition: 0.4s;
        z-index: -1;
      }
      &:hover {
        opacity: 1;
      }
      &.-red {
        background: $orange;
        border-color: $orange;
        color: #fff;
        &:hover {
          background: none;
        }
      }
      &.-grey {
        background: #fff;
        color: $orange;
        border: rem(2px) solid $orange;
        &::before {
          opacity: 0;
        }
        &:hover {
          color: #fff;
          border-color: $orange;
          &::before {
            opacity: 1;
          }
        }
      }
    }
    .field {
      position: relative;
      &__label {
        margin-bottom: rem(3px);
        font-size: rem(16px);
        line-height: rem(22px);
        color: rgba($grey, 0.7);
        font-family: $font-light;
        transition: 0.4s;
        z-index: 1;
        &-hint {
          font-family: $font-light;
          color: rgba($grey, 0.4);
        }
      }
      &__input {
        width: 100%;
        height: $field-height-sm;
        padding: $field-padding-checks-lg;
        font-size: $field-font-size-sm;
        max-width: 100%;
        appearance: none;
        font-family: $font-reg;
        background: #ffffff;
        border-radius: rem(50px);
        text-transform: none;
        transition: all 0.3s ease-in-out;
        border: $field-border;
        color: $grey;
        &::placeholder {
          color: $field-placeholder;
          font-family: $font-reg;
        }
      }
      &__error {
        color: $orange;
        font-family: $font-light;
        font-size: rem(12px);
        line-height: 1;
        margin-top: rem(2px);
      }
      &__icon {
        @include size(#{rem(22px)});
        font-size: 0;
        background: url("../assets/images/icons/success.svg");
        background-size: contain;
        &.-error {
          background: url("../assets/images/icons/error-small.svg");
          background-size: contain;
        }
      }
      .dp__input_icon {
        display: none;
      }
    }

    .uploader {
      .btn {
        color: transparent;
        &::after {
          content: "Добавить фото";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $orange;
        }
        &:hover {
          color: transparent;
          &::after {
            color: #fff;
          }
        }
      }
    }
    .check-info {
      display: inline-block;
      vertical-align: middle;
      margin-left: rem(3px);
      cursor: pointer;
    }
    @media (min-width: $md) {
      padding-top: rem(120px);
      &::before {
        content: "Назад";
        position: absolute;
        top: rem(113px);
        left: rem(44px);
        display: block;
        height: rem(25px);
        padding-left: rem(32px);
        padding-top: rem(2px);
        font-size: rem(18px);
        line-height: rem(25px);
        background: url("../assets/images/icons/arrow-back.svg") no-repeat left
          center;
        background-size: rem(18px);
        cursor: pointer;
      }
      &__container {
        width: rem(795px);
        padding: rem(55px) rem(154px) rem(50px) rem(213px);
        border-radius: rem(50px);
        box-shadow: rem(17px) rem(17px) 0 #c4eefa;
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 20%;
          transform: translateX(50%);
          display: block;
          @include size(rem(350px), rem(290px));
          background: url("../assets/images/check.png") no-repeat center;
          background-size: contain;
        }
      }
      &__close {
        top: rem(40px);
        right: rem(40px);
        @include size(#{rem(26px)});
      }
      .check-grabber {
        text-align: left;
        &__fail-icon {
          @include size(#{rem(75px)});
        }
        &__title {
          font-size: rem(36px);
          font-weight: normal;
          line-height: 1;
        }
        &__sub-title {
          max-width: rem(330px);
          margin-top: rem(20px);
          font-size: rem(12px);
          line-height: 1.2;
          strong {
            margin-bottom: rem(20px);
            font-size: rem(48px);
            line-height: 1;
          }
        }
        &__form {
          grid-row-gap: rem(20px);
        }
        &__scanner {
          width: rem(332px);
          border-radius: rem(20px);
          background: none;
          &-item {
            top: 0;
            left: 0;
            @include size(100% !important);
            border-radius: rem(20px);
          }
        }
        &__photos {
          width: rem(332px);
          gap: rem(20px) 0;
          margin-left: 0;
        }
        &__photo {
          &-item {
            flex: 1 1 auto;
            height: rem(157px);
          }
        }
      }
      .btn {
        max-width: rem(332px);
        padding: rem(25px);
        margin-right: auto;
        font-size: rem(18px);
      }
      .field {
        width: rem(332px);
        &__label {
          top: rem(18px);
          left: $field-offset-lg;
          font-size: rem(14px);
          &-hint {
            font-size: rem(12px);
          }
        }
        &__input {
          height: $field-height-lg;
          padding: $field-padding-checks-lg;
          font-size: $field-font-size-lg;
        }
        &__error {
          font-size: rem(12px);
        }
      }
    }
  }
}
</style>
